.content-block {
	position: relative;
	width: 100%;
	padding: 75px 0;
	background-color: #fff;

	&-alt {
		background-color: #1c2d56;
		color: #fff;
	}

	img.full-width {
		width: 100%;
	}

	&-bg-gray {
		background-color: #eee;
	}
}

.content {
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		font-family: $font-family-serif;
		font-weight: normal;
	}

	h2 {
		font-weight: normal;
		font-style: italic;
		font-size: 38px;
		@media only screen and (min-width: $screen-sm-min) {
			font-size: 48px;
		}
	}
	h3 {
		font-weight: normal;
		font-style: italic;
		font-size: 24px;
		@media only screen and (min-width: $screen-sm-min) {
			font-size: 32px;
		}
	}
	p,
	li {
		font-family: $font-family-sans-serif;
		font-weight: 300;
		line-height: 2;
	}
	.intro-text {
		font-size: 18px;
		font-weight: normal;
		font-style: italic;
		margin-bottom: 22px;
	}
	.mb0 {
		margin-bottom: 0;
	}
	p,
	ul {
		& + h1,
		& + h2,
		& + h3,
		& + h4,
		& + h5,
		& + h6 {
			margin-top: 22px; // dubbele van p/ul bottom margin
		}
	}
	ul.custom-dots {
		list-style: none;
		padding-left: 0;
		li {
			position: relative;
			padding-left: 24px;

			&:after {
				content: '';
				border-radius: 50%;
				display: block;
				width: 10px;
				height: 10px;
				position: absolute;
				left: 0;
				top: 9px;
				background-color: #00bdb7;
			}
		}
	}

	&-body {
		position: relative;
		padding-top: 20px;
		@media only screen and (min-width: $screen-sm-min) {
			padding-left: 60px;
		}

		&:before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 200px;
			height: 4px;
			background-color: $brand-primary;
		}

	}
}
[class^=col-] .content {
	margin-bottom: $grid-gutter-width;
	@media only screen and (min-width: $screen-sm-min) {
		margin-bottom: 0;
	}
}
