.content-block-homepage-header {
	min-height: 100vh;
	// het echte bestand heet bg-homepage-header.jpg, je kunt voor .jpg een .timestamp-nummertje
	// toevoegen om de lange caching te breken
	color: #fff;
	background-color: transparent;

	&-bg {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: #000 url(../images/bg-homepage-header.1.jpg) no-repeat center center;
		background-size: cover;
		@media (min-width: $screen-sm-min) {
			background-attachment: fixed;
		}
	}
	&.animate-in {
		@media (min-width: $screen-sm-min) {
			background-position: center 50vh;
			background-attachment: scroll;
		}
	}

	h1 {
		font-family: $font-family-serif;
		font-size: 32px;
		@media (min-width: $screen-sm-min) {
			font-size: 72px;
		}
		@media (min-width: $screen-lg-min) {
			font-size: 100px;
		}
		font-weight: 400;
		font-style: italic;
		line-height: 1.1;

		margin-top: 40px;
		@media (min-width: $screen-sm-min) {
			margin-top: 60px;
		}
	}
	h2 {
		font-family: Roboto, sans-serif;
		// font-weight: bold;
		font-size: 14px;
		letter-spacing: 2px;
		@media (min-width: $screen-sm-min) {
			font-size: 20px;
		}
	}

	.cta {
		font-family: $font-family-sans-serif;
		font-weight: 300;
		font-size: 25px;
		line-height: 1.5;

		padding-top: 40px;

		@media (min-width: $screen-sm-min) {
			padding-top: 120px;
		}
	}

	.btn-lg {
		font-size: 14px;
		margin-bottom: 10px;
		padding: 10px 24px;
		@media (min-width: $screen-sm-min) {
			font-size: 20px;
		}
		&:hover {
			background-color: #00bdb7;
			border-color: #00bdb7;
		}
	}
	.sub-cta {
		position: relative;
		color: #fff;
		font-family: Roboto, sans-serif;
		font-size: 14px;
		@media (min-width: $screen-sm-min) {
			font-size: 20px;
		}
		font-weight: bold;
		display: inline-block;

		&:after {
			content: '';
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			height: 1px;
			background-color: #fff;
		}
		// text-decoration: underline;
	}
	a.sub-cta:hover {
		text-decoration: none;
		color: #00bdb7;
		&:after {
			background-color: #00bdb7;
		}
	}
	.scroll-to-element {
		position: absolute;
		width: 64px;
		height: 64px;
		left: 50%;
		bottom: 32px;
		margin-left: -20px;
		padding: 12px;
		// transform: translateY(-12px);
		animation-name: bounceOut;
		animation-duration: 2s;
		animation-delay: 2s;
		animation-iteration-count: infinite;

		& svg {
			position: relative;
			display: block;
			width: 100%;
			height: 100%;
			fill: #fff;
		}

		&:hover svg {
			fill: #00bdb7;
		}
	}
	.logo-sterrenkubus {
		fill: #fff;
		width: 174px;
		height: 120px;
	}
}

.content-block-homepage-sub-header {
	background-color: rgba(0,0,0,.5);

	p {
		font-weight: 300;
		color: #fff;
		line-height: 1.5;

		font-family: $font-family-serif;
		font-size: 16px;
		@media (min-width: $screen-sm-min) {
			font-size: 24px;
		}
		&.header {
			font-family: $font-family-serif;
			font-style: italic;
			font-size: 18px;
			margin-bottom: 20px;
			@media (min-width: $screen-sm-min) {
				font-size: 32px;
			}
		}
	}
	p.sans {
		font-size: 14px;
		@media (min-width: $screen-sm-min) {
			font-size: 20px;
		}
	}
}

@keyframes bounceOut {
    0%, 60%, 75%, 90%, 100% {
        transform: translateY(-12px);
    }
    70% {
        transform: translateY(0);
    }
    85% {
        transform: translateY(-8px);
    }
}
