.content-block-breadcrumbs {
	padding: 40px 0;

	a,
	span {
		display: inline-block;
	}
	.breadcrumb-divider {
		margin: 0 10px;
	}
	// hide the last divider
	span + .breadcrumb-divider {
		display: none;
	}

	& + .content-block {
		padding-top: 0;
	}
}
