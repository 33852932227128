// where are me fontjes, absolute url
$font-path: 				'./../fonts';

// fonts
// $font-family-sans-serif:    'Montserrat', sans-serif;
$font-family-sans-serif:    Vollkorn, serif;
$font-family-serif:         Vollkorn, serif;
$font-size-base:          	16px !default;

$brand-primary: 			#0d7f7b;

$btn-primary-border:        $brand-primary;
