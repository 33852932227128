.content-block-footer {
	background-color: rgba(0,0,0,.5);
	color: #fff;

	.footer-menu {
		list-style: none;
		margin: 0;
		padding: 0 0 75px 0;

		& li {
			display: inline-block;
		}
	}

	.content {
		h3 {
			font-size: 32px;
			font-style: italic;
		}
	}
	.logo-sterrenkubus {
		fill: #fff;
		width: 174px;
		height: 120px;
	}

	a {
		color: #00bdb7;

		&:hover {
			color: #fff;
			text-decoration: none;
		}
	}
	.sub-cta {
		font-family: Roboto, sans-serif;
	}
	.btn-lg {
		font-size: 16px;
		margin-bottom: 10px;
		padding: 10px 24px;
		background-color: #dc4407;
		border-color: #dc4407;
		@media (min-width: $screen-sm-min) {
			font-size: 20px;
			margin-bottom: 10px;
		}
		&:hover {
			background-color: #f74b06;
			border-color: #f74b06;
		}
	}
}

.footer-menu {
	li {
		display: block;
		width: 100%;
		text-align: center;
		@media only screen and (min-width: $screen-sm-min) {
			display: inline-block;
			width: auto;
			text-align: initial;
		}
	}
	a {
		position: relative;
		display: inline-block;
		font-family: Roboto, sans-serif;
		font-weight: bold;
		letter-spacing: 2px;
		text-decoration: none;
		color: #fff;
		font-size: 14px;
		@media only screen and (min-width: $screen-sm-min) {
			font-size: 16px;
		}
		&:after {
			content: '';
			position: absolute;
			bottom: 3px;
			left: 0;
			right: 0;
			height: 1px;
			background-color: #fff;
		}

		&:hover {
			color: #00bdb7;
			text-decoration: none;

			&:after {
				background-color: #00bdb7;
			}
		}
	}
	.menu-divider {
		display: none;
		@media only screen and (min-width: $screen-sm-min) {
			display: inline-block;
		}
		margin: 0 10px;
	}
}
.footer-group {
	position: relative;
	background: #000 url(../images/bg-homepage-header.1.jpg) no-repeat center top;
	background-size: cover;
}
