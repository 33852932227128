.content-block-cta {
	.cta {
		font-family: $font-family-sans-serif;
		font-weight: 300;
		font-size: 25px;
		line-height: 1.5;

		padding-top: 40px;

	}
	.btn-lg {
		font-size: 14px;
		margin-bottom: 10px;
		padding: 10px 24px;
		@media (min-width: $screen-sm-min) {
			font-size: 20px;
			margin-bottom: 10px;
		}
		&:hover {
			background-color: #00bdb7;
			border-color: #00bdb7;
		}
	}
	.sub-cta {
		color: #00bdb7;
		font-family: Roboto, sans-serif;
	}
	a.sub-cta:hover {
		text-decoration: none;
		color: $link-color;
	}
	.sub-cta {
		position: relative;
		color: #00bdb7;
		font-family: Roboto, sans-serif;
		font-size: 14px;
		@media (min-width: $screen-sm-min) {
			font-size: 20px;
		}
		font-weight: bold;
		display: inline-block;

		&:after {
			content: '';
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			height: 1px;
			background-color: #00bdb7;
		}
		// text-decoration: underline;
	}
	a.sub-cta:hover {
		text-decoration: none;
		color: #0d7f7b;

		&:after {
			background-color: #0d7f7b;
		}
	}

}
