.content-block-arrangementen {
	.content-arrangement {
		opacity: 0;
		transform: translateY(200px);
		transition: opacity 440ms ease-out, transform 440ms ease-out;

		&.animate-in {
			opacity: 1;
			transform: translateY(0);
		}
		margin-bottom: $grid-gutter-width;
		@media only screen and (min-width: $screen-md-min) {
			margin-bottom: 0;
		}
	}

	h2 {
		margin-bottom: 25px;
		font-size: 24px;
		font-style: normal;
		@media only screen and (min-width: $screen-sm-min) {
			font-size: 34px;
		}
	}

	h3 {
		margin-top: 25px;
		position: relative;
		padding-bottom: 40px;
		margin-bottom: 0;

		&:after {
			content: '';
			position: absolute;
			bottom: 17px;
			left: 0%;
			// margin-left: -75px;
			width: 150px;
			height: 4px;
			background-color: $brand-primary;
		}
	}

}
