.content-block-header {
	padding: 20px 0;
	height: 160px;
	background: #000 url(../images/bg-vervolg-header.jpg) no-repeat center top;
	background-size: auto 160px;
	color: #fff;

	p {
		text-align: center;
		@media only screen and (min-width: $screen-sm-min) {
			text-align: left;
		}
	}
	& .logo-sterrenkubus {
		fill: #fff;
		width: 174px;
		height: 120px;
	}
}
