html {
    overflow-y: scroll;
}

html,
body {
    width: 100%;
    height: 100%;
}

/* Google Fonts are not rendering on Google Chrome: http://stackoverflow.com/a/22025804/598319 */
body {
    -webkit-perspective:initial;
    -webkit-backface-visibility:initial;
    -webkit-animation-delay: 0.1s;
    -webkit-animation-name: fontfix;
    -webkit-animation-duration: 0.1s;
    -webkit-animation-iteration-count: 1;
    -webkit-animation-timing-function: linear;
}

@-webkit-keyframes fontfix {
    from { opacity: 1; }
    to   { opacity: 1; }
}

::-webkit-input-placeholder {
    /* WebKit browsers */
    color: $input-color-placeholder;
}

:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: $input-color-placeholder;
}

::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: $input-color-placeholder;
}

:-ms-input-placeholder {
    /* Internet Explorer 10+ */
    color: $input-color-placeholder;
}
:focus,
a,
a:focus,
a:active,
a:hover {
    outline: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
a:focus,
a:active {
    text-decoration: none;
}

a:focus,
a:active,
button::-moz-focus-inner,
input[type="reset"]::-moz-focus-inner,
input[type="button"]::-moz-focus-inner,
input[type="submit"]::-moz-focus-inner,
select::-moz-focus-inner,
input[type="file"] > input[type="button"]::-moz-focus-inner {
    border: 0;
    outline: 0;
}
button,
input,
select,
textarea {
    outline: none;
    border: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol,
li {
    margin-top: 0;
}

h1:last-child,
h2:last-child,
h3:last-child,
h4:last-child,
h5:last-child,
h6:last-child,
p:last-child,
ul:last-child,
ol:last-child,
li:last-child {
    margin-bottom: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul {
    &.center {
        text-align: center;
    }
}

