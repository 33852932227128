/***
 * when doing a loop on a gallery or productlist with lots of cols in one row
 * use the .autoclear class to clear the first col that should float completely left
 * prevents wrong floats when cells are not the same height
 */
@mixin clear-row-first-child($col-type) {
    .col-#{$col-type}- {
        &1:nth-child(12n+1),
        &2:nth-child(6n+1),
        &3:nth-child(4n+1),
        &4:nth-child(3n+1),
        &6:nth-child(odd) {
            clear: left;
        }
    }
}
.autoclear {
    // @media (min-width: $screen-hd-min) {
    //     @include clear-row-first-child(hd);
    // }
    // @media (min-width: $screen-xl-min) and (max-width: $screen-xl-max) {
    //     @include clear-row-first-child(xl);
    // }
    // @media (min-width: $screen-lg-min) and (max-width: $screen-lg-max) {
    @media (min-width: $screen-lg-min) {
        @include clear-row-first-child(lg);
    }
    @media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
        @include clear-row-first-child(md);
    }
    @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
        @include clear-row-first-child(sm);
    }
    @media (max-width: $screen-xs-max) {
        @include clear-row-first-child(xs);
    }
}


/***
 * left and right align inline-blocks on one line
 * needs a little tweaking
 */
.single-line-justify {
    text-align: justify;
    // enable justify on one line
    &:after {
        content: '';
        display: inline-block;
        width: 100%;
        height: 1px;
        font-size: 1px
    }
}

/***
 * helper classes for showing/hiding content on different breakpoints
 * and linebreaks for specific breakpoints
 *
 * if you use one of these classes on an element the element will be visible
 * only for the given breakpoint. ofcourse you can use multiple classes
 * for visibility on several breakpoints
 */
.block-sm,
.block-md,
.block-lg,
.block-xl,
.block-hd,

.inline-sm,
.inline-md,
.inline-lg,
.inline-xl,
.inline-hd,

.inline-block-sm,
.inline-block-md,
.inline-block-lg,
.inline-block-xl,
.inline-block-hd,

.br-sm,
.br-md,
.br-lg,
.br-xl,
.br-hd {
    display: none;
}
.inline-xs {
    display: inline;
}
.inline-block-xs {
    display: inline-block;
}
.block-xs {
    display: block;
}
@media (min-width: $screen-sm-min) {
    .block-xs,
    .inline-xs,
    .inline-block-xs,
    .br-xs {
        display: none;
    }
}
@media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
    .block-sm {
        display: block;
    }
    .br-sm,
    .inline-sm {
        display: inline;
    }
    .inline-block-sm {
        display: inline-block;
    }
}
@media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
    .block-md {
        display: block;
    }
    .br-md,
    .inline-md {
        display: inline;
    }
    .inline-block-md {
        display: inline-block;
    }
}
// @media (min-width: $screen-lg-min) and (max-width: $screen-lg-max) {
@media (min-width: $screen-lg-min) {
    .block-lg {
        display: block;
    }
    .br-lg,
    .inline-lg {
        display: inline;
    }
    .inline-block-lg {
        display: inline-block;
    }
}
// @media (min-width: $screen-xl-min) and (max-width: $screen-xl-max) {
//     .block-xl {
//         display: block;
//     }
//     .br-xl,
//     .inline-xl {
//         display: inline;
//     }
//     .inline-block-xl {
//         display: inline-block;
//     }
// }
// @media (min-width: $screen-hd-min) {
//     .block-hd {
//         display: block;
//     }
//     .br-hd,
//     .inline-hd {
//         display: inline;
//     }
//     .inline-block-hd {
//         display: inline-block;
//     }
// }


.mt-0 {
    margin-top: 0 !important;
}
.mb-0 {
    margin-bottom: 0 !important;
}
.pt-0 {
    padding-top: 0 !important;
}
.pb-0 {
    padding-bottom: 0 !important;
}

