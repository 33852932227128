$typoprefix: '' !default;

#{$typoprefix}.typography {

	// image alignment classes from the wysiwyg
    .leftAlone {
        display: block;
        max-width: 100%;
    }
    .center {
        display: block;
        max-width: 100%;
        margin-left: auto;
        margin-right: auto;
    }
    .left {
        display: block;
        max-width: 100%;
        float: left;
        margin-right: 10px;
    }
    .right {
        display: block;
        max-width: 100%;
        float: right;
        margin-left: 10px;
    }
}

#{$typoprefix}.typography-alt {
	h1 {
		// color: $headings-h1-color-alt;
	}
	h2,
	h3,
	h4,
	h5,
	h6,
	p,
	li,
	td,
	th {
		// color: $text-color-alt;
	}

	a {
		// color: $link-color-alt;
	}
	a:hover {
		// color: $link-hover-color-alt;
	}
}
