.toggle-audio {
	position: absolute;
	display: block;
	right: 15px;
	top: 15px;
	color: #fff;
	font-size: 12px;
	line-height: 24px;

	&:hover,
	&:focus,
	&:active {
		color: #fff;
		text-decoration: none;
	}

	svg {
		display: inline-block;
		fill: #fff;
		width: 20px;
		height: 20px;
		vertical-align: text-bottom;
	}

	.audio-on {
		display: inline-block;
	}
	.audio-off {
		display: none;
	}

	&.muted {
		.audio-on {
			display: none;
		}
		.audio-off {
			display: inline-block;
		}
	}
}

.btn {
	border-radius: 0;
	font-family: Roboto, sans-serif;
	font-weight: bold;
	@media (min-width: $screen-sm-min) {
		letter-spacing: 2px;
	}

	&-lg {
		font-size: 16px;
		@media (min-width: $screen-sm-min) {
			font-size: 20px;
		}
	}
}

.sans {
	font-family: Roboto, sans-serif !important;

	&-wide {
		letter-spacing: 2px;
	}
}
