.content-block-usps {
	background-color: transparent;

	h3 {
		margin-top: 25px;
		font-style: italic;
		font-size: 36px;
		@media only screen and (min-width: $screen-sm-min) {
			font-size: 72px;
		}
		@media only screen and (min-width: $screen-lg-min) {
			font-size: 80px;
		}
	}
	p {
		font-size: 20px;
		@media only screen and (min-width: $screen-sm-min) {
			font-size: 20px;
		}

		&.sans-wide {
			font-weight: bold;
			font-size: 14px;
			@media only screen and (min-width: $screen-sm-min) {
				font-size: 20px;
			}
		}
	}
	.row-usps {
		margin-top: 25px;
	}
	ul {
		list-style: none;
	}
	li {
		position: relative;
		font-size: 18px;
		padding-left: 24px;

		&:after {
			content: '';
			border-radius: 50%;
			display: block;
			width: 10px;
			height: 10px;
			position: absolute;
			left: 0;
			top: 12px;
			background-color: #00bdb7;
		}
	}
	.cta {
		font-family: $font-family-sans-serif;
		font-weight: 300;
		font-size: 25px;
		line-height: 1.5;

		padding-top: 40px;

	}
	.btn-lg {
		font-size: 14px;
		margin-bottom: 10px;
		padding: 10px 24px;
		@media (min-width: $screen-sm-min) {
			font-size: 20px;
			margin-bottom: 10px;
		}
		&:hover {
			background-color: #00bdb7;
			border-color: #00bdb7;
		}
	}
	.sub-cta {
		position: relative;
		color: #fff;
		font-family: Roboto, sans-serif;
		font-size: 14px;
		@media (min-width: $screen-sm-min) {
			font-size: 20px;
		}
		font-weight: bold;
		display: inline-block;

		&:after {
			content: '';
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			height: 1px;
			background-color: #fff;
		}
		// text-decoration: underline;
	}
	a.sub-cta:hover {
		text-decoration: none;
		color: #00bdb7;
		&:after {
			background-color: #00bdb7;
		}
	}

}
