.content-block-de-plek {
		padding-bottom: 0;

	@media only screen and (min-width: $screen-md-min) {
		padding-top: 0;
	}
	.content {
		padding: 4vw 2vw;
	}

	h3 {
		font-size: 28px;
		// font-weight: bold;
		font-style: italic;
		@media only screen and (min-width: $screen-md-min) {
			font-size: 3vw;
		}
		@media only screen and (min-width: $screen-lg-min) {
			font-size: 44px;
		}
	}
	p {
		position: relative;
		padding-top: 20px;
		font-size: 16px;
		@media only screen and (min-width: $screen-md-min) {
			font-size: 1.5vw;
			padding-left: 60px;
		}
		@media only screen and (min-width: $screen-lg-min) {
			font-size: 18px;
		}

		&:before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 200px;
			height: 4px;
			background-color: $brand-primary;
		}

		&.sub-header {
			padding-left: 0;
			padding-top: 0;
			font-size: 18px;
			margin-bottom: 20px;
			@media only screen and (min-width: $screen-md-min) {
				font-size: 1.8vw;
			}
			@media only screen and (min-width: $screen-lg-min) {
				font-size: 22px;
			}

			&:before {
				display: none;
			}
		}
	}
}
